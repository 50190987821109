<template>
  <section
    class="section-intro-image tw-flex tw-h-[65vh] tw-items-end tw-justify-center tw-bg-cover tw-bg-center tw-pb-24"
    :style="[`background-image: url(${vars.backgroundImage})`, doesCircleCtaExist ? 'padding-top: 720px' : '']"
  >
    <div class="container" style="z-index: 3">
      <div class="tw-max-w-2xl">
        <library-title
          tag="h1"
          :text="vars.titleText"
          class="tw-mb-6 tw-text-5xl tw-text-white lg:!tw-text-6xl"
          style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2)"
        />
        <p
          v-if="vars.descriptionText"
          class="font-arial tw-mb-6 tw-text-white lg:tw-mb-10"
          style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2)"
        >
          {{ vars.descriptionText }}
        </p>
        <div
          v-if="vars.buttons1LabelText.length > 0"
          class="tw-grid tw-grid-cols-1 tw-items-center tw-gap-4 lg:tw-grid-cols-2"
          style="z-index: 3"
        >
          <div v-for="(button, index) in buttons" :key="`button-${index}`">
            <nuxt-link
              :to="button.href"
              :external="true"
              :class="{
                'pxp-search-properties-btn': button.type === 'primary',
                'pxp-primary-cta pxp-animate after:tw-border-inherit': button.type === 'secondary',
              }"
              :style="`background-color: var(--${button.background}); color: var(--${button.text}); ${
                button.type === 'secondary' ? `border-top-color: var(--${button.text});` : ''
              }`"
            >
              <!-- TODO COCKPIT for only Grouped Variables: background-> background-color, text -> text-color -->
              {{ button.label }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'SectionIntro',

  mixins: [ComponentMixin],

  data() {
    return {
      doesCircleCtaExist: false,
    };
  },

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },

  mounted() {
    const circleCTA = document.getElementById('theme3-circle-cta');
    if (circleCTA) {
      this.doesCircleCtaExist = true;
    }
  },
});
</script>

<style scoped>
.section-intro-image {
  position: relative;
}

.section-intro-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Black background with 50% opacity */
  z-index: 1;
}
</style>
