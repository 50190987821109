<template>
  <div>
    <div
      v-if="variants.withIcons === variant"
      class="pt-100 pb-100 tw-relative"
      :style="`background: var(--${vars.backgroundColor})`"
    >
      <div
        v-if="isLeftBox"
        class="tw-z-1 tw-absolute tw-left-0 tw-top-0 tw-h-[280px] tw-w-full md:tw-h-[360px] xl:tw-h-full xl:tw-w-[568px] 2xl:tw-w-[640px]"
        style="background-color: var(--purple)"
      />
      <div class="container tw-relative tw-z-10">
        <library-title :text="vars.titleText" tag="h2" class="tw-mb-8 lg:tw-mb-16" />
        <div class="align-items-start tw-grid tw-h-full tw-grid-cols-1 tw-gap-12 lg:tw-gap-16 xl:tw-grid-cols-2">
          <div>
            <library-image v-if="vars.backgroundImage" :src="vars.backgroundImage"></library-image>
          </div>
          <div>
            <div v-for="(feature, index) in features" :key="index" class="tw-mb-7 tw-flex tw-items-start tw-gap-9">
              <NuxtImg :src="feature.icon" class="tw-h-[80px] tw-w-[80px] lg:tw-h-[100px] lg:tw-w-[100px]" />
              <div class="tw-flex tw-flex-col tw-gap-4 tw-pt-2">
                <library-text type="bold" class="!tw-text-2xl" :text="feature.title" />
                <library-text :text="feature.description" style="color: var(--nc-mini-title-color)" />
              </div>
            </div>
            <div v-if="vars.buttonText" class="tw-flex tw-items-center tw-gap-4">
              <nuxt-link
                :to="vars.buttonUrl"
                :external="true"
                :class="{
                  'pxp-search-properties-btn': vars.buttonTypeText === 'primary',
                  'pxp-primary-cta pxp-animate after:tw-border-inherit': vars.buttonTypeText === 'secondary',
                }"
                :style="`color: var(--${vars.buttonTextColor}); ${
                  vars.buttonTypeText === 'secondary' ? `border-top-color: var(--${vars.buttonTextColor});` : ''
                }`"
              >
                {{ vars.buttonText }}
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="pt-100 pb-100 tw-relative" :style="`background: var(--${vars.backgroundColor})`">
      <div
        v-if="isRightBox"
        style="
          position: absolute;
          top: 0;
          right: 0;
          width: 528px;
          height: 468px;
          z-index: 1;
          background-color: var(--purple);
        "
      />
      <div
        class="tw-px-1"
        :class="{
          container: variants.v2 === variant,
        }"
      >
        <div
          class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2"
          :class="{
            'tw-items-center tw-gap-7 lg:tw-gap-20': variants.v2 === variant,
          }"
        >
          <!-- Video container -->
          <div
            class="video tw-z-10 tw-flex tw-items-center tw-justify-center xl:tw-justify-start"
            style="position: relative"
            :class="{
              'order-last lg:order-first': vars.isReversedNumber === 1,
            }"
          >
            <library-image v-if="vars.backgroundImage" :src="vars.backgroundImage"></library-image>
            <iframe
              v-else-if="!vars.backgroundImage"
              width="100%"
              :class="{
                'xl:-tw-h-[800px] tw-h-[380px] md:tw-h-[480px] lg:tw-my-6 lg:tw-h-[500px] xl:tw-my-8':
                  variants.v1 === variant,
                'tw-h-[360px] md:tw-h-[460px]': variants.v2 === variant,
              }"
              :src="vars.backgroundVideo"
              :title="vars.titleText"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
            <div
              v-if="vars.backgroundVideo"
              class="tw-absolute tw-z-10 tw-h-full tw-w-full"
              @click="isPopupVisible = true"
            >
              <button
                class="pxp-video-section-trigger tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2"
              >
                <span class="fa fa-play"></span>
              </button>
            </div>
          </div>

          <!-- Content  -->
          <div
            class="tw-px-5"
            :class="{
              'order-first lg:order-last': vars.isReversedNumber === 1,
              'tw-py-12 tw-shadow-[8px_16px_100px_rgba(0,0,0,0.1)] lg:-tw-ml-4 lg:tw-rounded-bl lg:tw-rounded-tl lg:tw-py-24 lg:tw-pl-10 2xl:tw-py-32 2xl:tw-pl-24':
                variants.v1 === variant,
            }"
            :style="`background: var(--${vars.backgroundColor})`"
          >
            <div class="tw-flex tw-flex-col tw-gap-7 lg:tw-max-w-[640px] xl:tw-gap-12">
              <library-title tag="h2" :text="vars.titleText" />
              <library-text :text="vars.descriptionText" />
              <div class="tw-container tw-grid tw-gap-6 md:tw-grid-cols-2 xl:tw-gap-12">
                <div class="tw-space-y-4 lg:tw-space-y-6">
                  <div
                    v-for="(item, index) in featuresColumns1"
                    :key="`feature-${index}`"
                    class="tw-flex tw-items-start tw-gap-4"
                  >
                    <svg
                      class="tw-shrink-0"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                        :fill="`var(--${vars.iconColor})`"
                      />
                    </svg>
                    <div>
                      <library-text type="bold" class="tw-text-base" :text="item.feature" />
                    </div>
                  </div>
                </div>

                <div v-if="featuresColumns2.length > 0" class="tw-space-y-4 lg:tw-space-y-6">
                  <div
                    v-for="(item, index) in featuresColumns2"
                    :key="`feature-2-${index}`"
                    class="tw-flex tw-items-start tw-gap-4"
                  >
                    <svg
                      class="tw-flex-shrink-0"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                        :fill="`var(--${vars.iconColor})`"
                      />
                    </svg>
                    <div>
                      <library-text type="bold" class="tw-text-base" :text="item.feature" />
                    </div>
                  </div>
                </div>

                <div v-if="vars.buttonText" class="tw-flex tw-items-center tw-gap-4">
                  <nuxt-link
                    :to="vars.buttonUrl"
                    :external="true"
                    :class="{
                      'pxp-search-properties-btn': vars.buttonTypeText === 'primary',
                      'pxp-primary-cta pxp-animate after:tw-border-inherit': vars.buttonTypeText === 'secondary',
                    }"
                    :style="`background-color: var(--${
                      vars.buttonBackgroundColor
                    }); color: var(--${vars.buttonTextColor}); ${
                      vars.buttonTypeText === 'secondary' ? `border-top-color: var(--${vars.buttonTextColor});` : ''
                    }`"
                  >
                    {{ vars.buttonText }}
                  </nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <library-popups-video-popup
        v-if="vars.backgroundVideo"
        v-model:is-visible="isPopupVisible"
        :video-url="vars.backgroundVideo"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'VideoBackground',

  mixins: [ComponentMixin],

  data(vm) {
    return {
      isPopupVisible: false,
      variants: {
        v1: 'v1',
        v2: 'v2',
        withIcons: 'withIcons',
      },
    };
  },

  computed: {
    features() {
      return this.groupedVariables.features;
    },
    featuresColumns1() {
      return this.groupedVariables.column_1_features;
    },

    featuresColumns2() {
      return this.groupedVariables.column_2_features;
    },

    isRightBox() {
      return this.vars.backgroundObjectsText === 'right-top-box';
    },

    isLeftBox() {
      return this.vars.backgroundObjectsText === 'left-bottom-box';
    },

    variant() {
      return useVariant(this.component);
    },
  },
});
</script>
